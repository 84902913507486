<template>
  <div>
    <div class="entry">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3 class="uk-text-background">パスワード変更</h3>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <table>
          <tr>
            <td class="uk-text-left uk-text-bold uk-form-label">
              <p>現在のパスワード：</p>
            </td>
            <td>
              <input
                type="password"
                name=""
                v-model="now_pass"
                class="uk-input uk-form-small uk-form-width-middle"
              />
            </td>
          </tr>
          <tr>
            <td class="uk-text-left uk-text-bold uk-form-label">
              <p>パスワード(4文字以上)：</p>
            </td>
            <td>
              <input
                type="password"
                name=""
                v-model="pass"
                class="uk-input uk-form-small uk-form-width-middle"
              />
            </td>
          </tr>
          <tr>
            <td class="uk-text-left uk-text-bold uk-form-label">
              <p>パスワード再入力：</p>
            </td>
            <td>
              <input
                type="password"
                name=""
                v-model="confirm_pass"
                class="uk-input uk-form-small uk-form-width-middle"
              />
            </td>
          </tr>
        </table>
        <br />
        <div
          class="
            uk-container
            uk-flex
            uk-flex-center
            uk-flex-middle
            uk-grid
            uk-padding
            uk-padding-remove-top
          "
        >
          <div class="uk-width-1-1">
            <button
              type="button"
              name="button"
              @click="change_pw"
              class="uk-button uk-button-primary uk-width-1-2"
            >
              変更
            </button>
          </div>
          <div class="uk-width-1-1 uk-margin-small">
            <button
              type="button"
              name="button"
              @click="$router.back()"
              class="uk-button uk-button-default uk-width-1-2"
            >
              &lt;&nbsp;戻る
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      now_pass: "",
      pass: "",
      confirm_pass: "",
      tmp: null,
      tmp1: null,
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "パスワード変更";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.checkLogined();
    this.$store.state.common.sDataLoading = false;
  },
  methods: {
    checkLogined() {
      if (!this.$store.state.login_status_c) {
        this.$router.replace("/client-login");
      }
    },
    change_pw() {
      if (!window.confirm("パスワードを変更しますか？")) return;
      if (this.pass != this.confirm_pass) {
        window.alert("パスワードが一致しません");
        return;
      }
      if (
        this.now_pw != "" &&
        this.pass != "" &&
        this.confirm_pass != "" &&
        this.pass == this.confirm_pass &&
        this.pass.length >= 4
      ) {
        let createHash = require("sha256-uint8array").createHash;
        this.tmp = createHash().update(this.now_pass).digest("hex");
        let createHash1 = require("sha256-uint8array").createHash;
        this.tmp1 = createHash1().update(this.pass).digest("hex");

        this.$axios
          .post("/client/change_password", {
            alias_id: this.$store.state.alias_id,
            email: this.$store.state.email,
            now_pass: this.tmp,
            after_pass: this.tmp1,
          })
          .then((response) => {
            if (response.data.flg) {
              window.alert("パスワードの変更が完了しました");
              sessionStorage.setItem("pass_c", this.tmp1);
              this.$router.replace("/client-menu");
            } else {
              window.alert("パスワードが正しくありません");
            }
          })
          .bind((error) => {
            console.log(error);
            window.alert("失敗しました");
          });
      } else if (
        this.now_pw == "" ||
        this.pass == "" ||
        this.confirm_pass == ""
      ) {
        window.alert("入力してください");
      } else if (this.pass.length < 4) {
        window.alert("パスワードが短すぎます");
      }
    },
  },
};
</script>

<style scoped>
p {
  display: inline;
  text-align: right;
}
</style>
